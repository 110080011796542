import Axios, { AxiosHeaders } from 'axios'
import { useRequestsStore } from '@/stores/requests'
import { useAuth } from '@simplifi/auth'

const api = Axios.create()

export function configureApiService() {
  const requests = useRequestsStore()

  api.interceptors.request.use(async (config) => {
    requests.All.increment()
    config.baseURL = import.meta.env.VITE_APP_API_BASE_URI
    config.headers ??= new AxiosHeaders()
    config.headers.Authorization = `bearer ${await useAuth().getToken()}`
    return config
  })

  api.interceptors.response.use(
    (response) => {
      requests.All.decrement()
      return response
    },
    (error) => {
      requests.All.decrement()

      if (error.response) {
        console.error(
          ['API response failed', error.message, error.response?.data?.Message]
            .filter(Boolean)
            .join(' | '),
          error,
        )
      } else if (error.request) {
        console.error(
          ['API request failed', error.message, `URL: ${error.config?.url}`]
            .filter(Boolean)
            .join(' | '),
          error,
        )
      } else {
        console.error('API error', error)
      }
      return Promise.resolve(error.response || error.request || { status: 400 })
    },
  )

  return api
}

export default api
