import {
  mdiCalendarRange,
  mdiClockOutline,
  mdiContentSave,
  mdiTagText,
} from '@mdi/js'
import type { VuetifyOptions } from 'vuetify/lib/framework.mjs'

export const vuetifyOptions: VuetifyOptions = {
  defaults: {
    VAutocomplete: {
      density: 'comfortable',
    },
    VDialog: {
      persistent: true,
      maxWidth: '50rem',
    },
    VSelect: {
      density: 'comfortable',
    },
    VTooltip: {
      contentClass: 'shadow',
    },
  },
  icons: {
    aliases: {
      timer: mdiClockOutline,
      tag: mdiTagText,
      save: mdiContentSave,
      calendarRange: mdiCalendarRange,
    },
  },
}
