<template>
  <v-navigation-drawer
    v-model="isMenuActive"
    class="nav-drawer text-left"
    bottom
    temporary
    width="300"
  >
    <v-list>
      <v-tabs v-model="tab" color="primary">
        <v-tab value="HE">HE</v-tab>
        <v-tab v-if="showSI" value="SI">SI</v-tab>
      </v-tabs>

      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="HE">
          <template v-for="item in itemsForHE" :key="item.name">
            <v-list-item
              v-if="!item.children"
              :prepend-icon="item.icon"
              :title="item.text"
              :to="{ name: item.name }"
              :exact="true"
            />

            <v-list-group v-else>
              <template #activator="{ props }">
                <v-list-item
                  v-bind="props"
                  :prepend-icon="item.icon"
                  :title="item.text"
                />
              </template>

              <v-list-item
                v-for="child in item.children"
                :key="child.name"
                :value="child.name"
                :title="child.text"
                :prepend-icon="child.icon"
                :to="{ name: child.name }"
                :exact="true"
              />
            </v-list-group>
          </template>

          <v-list-item
            v-if="hasConfigAccess()"
            prepend-icon="$edit"
            to="/config"
          >
            Config
          </v-list-item>

          <v-list-item
            v-if="
              useAuth().hasPermission(
                'Operations.WorkstreamStatistics' as never,
              )
            "
            prepend-icon="$graph"
            :to="{ name: 'stats' }"
          >
            Stats
          </v-list-item>
        </v-tabs-window-item>

        <v-tabs-window-item value="SI">
          <template v-for="item in itemsForSI" :key="item.name">
            <v-list-item
              :prepend-icon="item.icon"
              :title="item.text"
              :to="{ name: item.name }"
              :exact="true"
            />
          </template>
        </v-tabs-window-item>
      </v-tabs-window>

      <v-spacer />

      <template
        v-if="
          useAuth().hasPermission('Operations.ControlRoomStatistics' as never)
        "
      >
        <v-divider />

        <v-list-item prepend-icon="$graph" :to="{ name: 'controlRoom' }">
          Control Room
        </v-list-item>
      </template>

      <template v-if="showAuditing">
        <v-divider />

        <v-list-item prepend-icon="$history" :to="{ name: 'auditing' }">
          Auditing
        </v-list-item>
      </template>

      <v-divider />

      <v-list-item prepend-icon="$signOut" @click="signOut">
        Sign Out
      </v-list-item>
      <SfiThemeToggle />
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { useAuth } from '@simplifi/auth'
import { heItems, siItems } from '@/router/navigation-drawer'
import { computed, ref } from 'vue'
import { flag } from '@/lib/flags'
import { useNav } from '@simplifi/components'
import { hasConfigAccess } from '@/lib/auth'

const itemsForHE = ref(heItems)
const itemsForSI = ref(siItems)
const { isMenuActive } = useNav()
const tab = ref('HE')
const showSI = flag.siDashboard()

const auditingFlag = flag.auditingDashboard()
const showAuditing = computed(
  () =>
    auditingFlag && useAuth().hasPermission('Operations.AuditRecords' as never),
)

const signOut = useAuth().signOut
</script>

<style scoped>
/* Style list as flex so we can push things to the bottom easily */
/* Also allow overflow-y so if the menu gets large / used on mobile we can scroll it */
.v-list {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-y: auto !important;
  padding: 0;
}
/* Prevent menu items being visually selectable */
.v-list-item-title {
  user-select: none;
}
</style>
