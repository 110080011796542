import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import SILayout from '@/layouts/SILayout.vue'
import { useAuth } from '@simplifi/auth'
import { registerGuard } from './router-guard'
import { hasConfigAccess } from '@/lib/auth'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: DefaultLayout,
      meta: {
        requiresAuth: true,
        title: 'Home Emergency',
      },

      children: [
        {
          path: '',
          name: 'home',
          meta: { title: 'HE - Workstreams' },
          component: () => import('@/views/HomeView.vue'),
        },
        {
          path: 'auditing',
          name: 'auditing',
          meta: { title: 'Auditing' },
          component: () => import('@/views/AuditingView.vue'),
        },
        {
          path: 'he/complaints',
          name: 'he/complaints',
          meta: { title: 'HE - Complaints' },
          component: () => import('@/views/ComplaintsView.vue'),
        },
        {
          path: 'config',
          name: 'config',
          meta: { title: 'Config' },
          component: () => import('@/layouts/ConfigLayout.vue'),
          beforeEnter: () => {
            if (!hasConfigAccess()) {
              return { name: 'permissionError' }
            }
          },
          children: [
            {
              path: '',
              name: 'timers',
              component: () => import('@/views/Config/TimersView.vue'),
              beforeEnter: () => {
                if (
                  !useAuth().hasPermission(
                    'Operations.ConfigureWorkstreamTimers' as never,
                  )
                ) {
                  return { name: 'permissionError' }
                }
              },
            },
            {
              path: 'short-codes',
              name: 'shortCodes',
              component: () => import('@/views/Config/ShortCodesView.vue'),
              beforeEnter: () => {
                if (
                  !useAuth().hasPermission(
                    'Operations.ConfigureShortCodes' as never,
                  )
                ) {
                  return { name: 'permissionError' }
                }
              },
            },
            {
              path: 'trade-categories',
              name: 'tradeCategories',
              component: () => import('@/views/Config/TradeCategoriesView.vue'),
              beforeEnter: () => {
                if (
                  !useAuth().hasPermission(
                    'Operations.ConfigureTradeCategories' as never,
                  )
                ) {
                  return { name: 'permissionError' }
                }
              },
            },
            {
              path: 'data',
              name: 'data',
              component: () => import('@/views/Config/DataView.vue'),
            },
          ],
        },
        {
          path: 'control-room',
          name: 'controlRoom',
          meta: { title: 'Control Room' },
          component: () => import('@/views/ControlRoomView.vue'),
          beforeEnter: () => {
            if (
              !useAuth().hasPermission(
                'Operations.ControlRoomStatistics' as never,
              )
            ) {
              return { name: 'permissionError' }
            }
          },
        },
        {
          path: 'stats',
          name: 'stats',
          meta: { title: 'Stats' },
          component: () => import('@/views/StatsView.vue'),
          beforeEnter: () => {
            if (
              !useAuth().hasPermission(
                'Operations.WorkstreamStatistics' as never,
              )
            ) {
              return { name: 'permissionError' }
            }
          },
        },
        {
          path: 'examples',
          name: 'examples',
          meta: { title: 'Examples' },
          children: [
            {
              path: 'charts',
              name: 'charts',
              meta: { title: 'Charts Example' },
              component: () => import('@/views/ChartsExampleView.vue'),
            },
          ],
        },
      ],
    },

    {
      path: '/si',
      component: SILayout,
      meta: {
        requiresAuth: true,
        title: 'Site Investigation',
      },

      children: [
        {
          path: '',
          name: 'si',
          meta: { title: 'SI - Workstreams' },
          component: () => import('@/views/SIView.vue'),
        },
      ],
    },

    {
      path: '/identity/callback',
      component: AuthLayout,
      meta: {
        requiresAuth: true,
      },

      children: [
        {
          path: '',
          name: 'identityCallback',
          component: () =>
            import('@/views/authentication/IdentityCallback.vue'),
        },
      ],
    },

    {
      path: '/signin/error',
      name: 'signinError',
      meta: { title: 'Error' },
      component: () => import('@/views/authentication/SigninError.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      meta: { title: '404 - Not Found' },
      component: () => import('@/views/Errors/Error404.vue'),
    },
  ],
})

registerGuard(router)

router.beforeEach((to, from, next) => {
  document.title = [to.meta.title, `${import.meta.env.VITE_APP_TITLE}`]
    .filter((t) => !!t)
    .join(' | ')

  next()
})

export default router
