import { useAuth } from '@simplifi/auth'
import type { RouteLocationNormalized, Router } from 'vue-router'

export function registerGuard(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized) => {
    if (to.meta.requiresAuth) {
      const auth = useAuth()
      if (!(await auth.signIn())) {
        return { name: 'signinError' }
      }
    }

    return true
  })
}
