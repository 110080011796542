<template>
  <div class="d-flex flex-column justify-center align-center h-screen">
    <h1>Permission Denied</h1>
    <p>Your account lacks permissions to view this page</p>

    <v-btn class="mt-4" prepend-icon="$signOut" @click="signOut">
      Sign Out
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { useAuth } from '@simplifi/auth'

const signOut = useAuth().signOut
</script>
