<template>
  <v-layout full-height class="flex-column gap-4 justify-center align-center">
    <h1>
      {{ title }}
      <v-progress-linear indeterminate rounded color="primary" />
    </h1>
  </v-layout>
</template>

<script setup lang="ts">
const title = import.meta.env.VITE_APP_TITLE
</script>
