<template>
  <template v-if="appAccess">
    <NavBar
      :app-title="title"
      :page-title="route.meta.title as string"
      :session="session"
      :apps="session.applications"
      @sign-out="signOut"
    />

    <div class="main-app d-flex h-screen">
      <NavDrawer />

      <div class="w-100 overflow-y-auto">
        <router-view v-slot="{ Component }">
          <Transition :mode="'out-in'" :appear="true">
            <Suspense>
              <v-main class="layout pa-8 h-100">
                <component :is="Component" />
              </v-main>

              <template #fallback>
                <v-progress-circular />
              </template>
            </Suspense>
          </Transition>
        </router-view>
      </div>
    </div>
  </template>

  <AuthLayout v-else>
    <PermissionError />
  </AuthLayout>
</template>

<script setup lang="ts">
import { NavBar } from '@simplifi/components'
import NavDrawer from '@/components/NavDrawer.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import { flag, init } from '@/lib/flags'
import PermissionError from '@/views/authentication/PermissionError.vue'
import { useAuth } from '@simplifi/auth'
import { useRoute } from 'vue-router'

const route = useRoute()

await init()

const appAccess = flag.appAccess()

const title = import.meta.env.VITE_APP_TITLE

const auth = useAuth()
const { session, signOut } = auth
</script>

<style>
.layout {
  display: grid;
  grid-template-rows: auto 1fr;
}
</style>
